import React, {useState} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import configData from "../config/config.json";
import TextField from '@mui/material/TextField';
import '../Login.css';
import CircularProgress from '@mui/material/CircularProgress';
import i18next from "i18next";
import common_en from "../translations/en/common.json";
import common_bg from "../translations/bg/common.json";
import common_ro from "../translations/ro/common.json";
import common_lt from "../translations/lt/common.json";
import common_lv from "../translations/lv/common.json";
import common_hr from "../translations/hr/common.json";
import { initReactI18next, useTranslation } from "react-i18next";
const resources = {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    bg: {
        common: common_bg
    },
    lv: {
        common: common_lv
      },
      lt: {
        common: common_lt
      },
      hr: {
        common: common_hr
      },
    ro: {
        common: common_ro
    }
  }

  
  i18next
  .use(initReactI18next)
  .init({resources, lng: configData.MAIN_CONFIGURATIONS.LANGUAGE});

export default function Login(props) {

    const { t, i18n } = useTranslation('common');
    const [username, setUsername] = React.useState('');
    const [waitingMessage, setwaitingMessage] = React.useState(false);

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const [password, setPassword] = React.useState('');

    const handleChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const loginCheck = () => {

            setwaitingMessage(true)
  
          const dataLogin = { "username": username, "password": password };
  
          const reqLogin = async () => {

            const responseData = await axios.post('https://hr-api.dashboard.vip-intercars.bg/checklogin', dataLogin, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest' //the token is a variable which holds the token
                }}).then((results) => {

                    if(results.data.length > 0 && results.status == 200){
                            sessionStorage.setItem('tokenDashboard', results.data);

                            const userData = async () => { await axios.post('https://hr-api.dashboard.vip-intercars.bg/verifyToken', {"token":results.data}, {headers: {
                                'X-Requested-With': 'XMLHttpRequest' //the token is a variable which holds the token
                            }}).then((resultsVerify) => { sessionStorage.setItem('username', resultsVerify.data.email); sessionStorage.setItem('firstname', resultsVerify.data.firstname); sessionStorage.setItem('lastname', resultsVerify.data.lastname); }).catch(error => { sessionStorage.removeItem('tokenDashboard'); console.log(error.message) })}

                            userData();
                            
                            ReactDOM.render(<Alert severity="success">
                                <AlertTitle>{t('login.login_message_sucess')}</AlertTitle>
                            </Alert>, document.getElementById('message-status'));
                            setwaitingMessage(false)
                            
                            setTimeout(function(){
                            window.location.reload(true);
                        }, 2000);
                    } else if(results.data.status == 401) {
                        setwaitingMessage(false)
                        ReactDOM.render(<Alert severity="error">
                    <AlertTitle>{t('login.login_message_error')}</AlertTitle>
                    </Alert>, document.getElementById('message-status'));
                    }
                }).catch(error => {console.log(error);
                    setwaitingMessage(false)
                    ReactDOM.render(<Alert severity="error">
                    <AlertTitle>{error.message}</AlertTitle>
                    </Alert>, document.getElementById('message-status'));
                })

           }

           reqLogin()

    };

    if(waitingMessage){
        ReactDOM.render(
        <CircularProgress />
        , document.getElementById('message-status'))
    }


    return (
        <>
        <div className="Login">
        <div id="logo-login-form"><img id="logo-login-form-img" src="https://bg.marketplace.intercars.eu/media/logo/default/logo_Marketplace.jpg"></img></div>
        <div id="message-status"></div>
        <div id="login-username">
              <TextField
                id="outlined-required"
                label={t('login.username')}
                value={username}
                onChange={handleChangeUser}
              /></div>
        <div id="login-password">    
            <TextField
                id="outlined-password-input"
                label={t('login.password')}
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={handleChangePass}
                /></div>

        <div id="login-submit">
              <Button size="small" variant="outlined" onClick={() => loginCheck()}>
              {t('login.login_button')}
            </Button>
        </div>
        </div>
        </>
    )

}